<template>
  <div class="radio-btn-group">
    <div
      class="radio"
      :class="{ disabled: disabled }"
      v-for="option in options"
      :key="option.value"
      v-if="!option.deprecated"
    >
      <template>
        <input
          type="radio"
          checked="checked"
          :id="option.value"
          :name="option.value"
          :value="option.value"
          v-model="checked"
          :disabled="disabled"
        />
        <label :for="option.value">
          <div class="icon" v-if="option.icon">
            <img :src="require(`@/assets/images/icons/${option.icon}`)" />
          </div>
          <span>{{ option.text }}</span>
          <span v-if="option.deprecated">
            ({{
              lang.botMaker.addBot.engineSetting.deprecated[languageSelected]
            }})
          </span>
        </label>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'ButtonGroup',
  props: {
    value: [Number, String],
    options: {
      type: Array,
      required: true
    },
    selected: [Number, String],
    disabled: Boolean,
    showDisabled: {
      type: Boolean,
      default: false
    },
    bgColor: {
      type: String,
      default: 'primary'
    }
  },
  data() {
    return {
      checked: ''
    }
  },
  watch: {
    checked() {
      this.$emit('input', this.checked)
    }
  },
  computed: {
    ...mapState(['lang', 'languageSelected'])
  },
  mounted() {
    this.checked = this.selected
  }
}
</script>

<style lang="scss" scoped>
.radio-btn-group {
  display: flex;
  flex-wrap: wrap;
  .radio {
    display: flex;
    &:first-of-type {
      label {
        border-radius: 15px 0 0 15px;
      }
    }
    &:last-of-type {
      label {
        border-radius: 0 15px 15px 0;
      }
    }
    &:not(:first-of-type) {
      label {
        border-left: none;
      }
    }
    &.disabled {
      label {
        background: #efedef;
        color: #acacac;
        cursor: not-allowed;
        &:hover {
          box-shadow: none;
        }
      }
    }
    label {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: #fff;
      border: 1px solid #ddd;
      padding: 10px;
      cursor: pointer;
      color: #444;
      transition: box-shadow 400ms ease;
      &:hover {
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
      }
      .icon {
        background-color: white;
        padding: 5px;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        margin-bottom: 5px;
        img {
          width: 20px;
          margin-right: 5px;
        }
      }
    }
    input[type='radio'] {
      display: none;
    }
    input[type='radio']:checked + label {
      background: rgba(var(--vs-primary), 1);
      border-color: rgba(var(--vs-primary), 1);
      color: #fff;
    }
  }
}
</style>
